import React from "react";
// import classNames from "classnames";
import { ContactList, TagList } from "@molecules";
import { Container, Text, Image, ButtonAlt, AnimateIn } from "@atoms";

const Hero = ({ heading, image, backLink, contact, tags }) => {
  return (
    <section className="relative flex flex-col pt-12 md:pt-16">
      <Container>
        <div className="flex flex-col gap-12 md:gap-20">
          <div className="relative z-10 flex flex-col gap-12 md:gap-16">
            {backLink && (
              <ButtonAlt to={backLink} reverse>
                Return
              </ButtonAlt>
            )}
            <AnimateIn
              preset="fadeUpFast"
              className="flex flex-wrap items-center gap-8 sm:gap-12"
            >
              {image && (
                <Image
                  image={image}
                  aspectRatio={[1, 1]}
                  className="h-36 w-36 flex-shrink-0 rounded-full sm:h-44 sm:w-44"
                />
              )}
              <div className="relative sm:-top-0.5 sm:flex-1">
                <Text
                  variant="headingSm"
                  className="bg-heading inline whitespace-pre-line"
                >
                  {heading}
                </Text>
              </div>
            </AnimateIn>
          </div>
          {!!contact?.length && <ContactList items={contact} />}
          {!!tags?.length && <TagList tags={tags} />}
        </div>
      </Container>
    </section>
  );
};

export default Hero;

Hero.defaultProps = {};
